import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .service('mailAlertField', mailAlertField);

/**
 * Service constructor.
 * @constructor
 */
function mailAlertField() {
    let service = this;


    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'mailAlertId': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please choose an alert',
                        'requiredType': 'stringMustNotBeEmpty'
                    }
                },
                'applyOnNewSubmission': {
                    'validation': {
                        'required': false
                    }
                },
                'applyWhenEditing': {
                    'validation': {
                        'required': false
                    }
                },
                'manuallySendWithButton': {
                    'validation': {
                        'required': false
                    }
                },
                'manuallySendButtonText': {
                    'validation': {
                        'required': false
                    }
                },
                'buttonColour' : {
                    'validation': {
                        'required': false
                    }
                },
                'fontAwesomeIcon' : {
                    'validation': {
                        'required': false
                    }
                },
                'isRunOnSchedule' : {
                    'validation': {
                        'required': false
                    }
                },
                'selectFrequency' : {
                    'validation': {
                        'required': false
                    }
                },
                'selectTypeOfFrequency' : {
                    'validation': {
                        'required': false
                    }
                },
                'conditionType' : {
                    'validation': {
                        'required': false
                    }
                },
                'templateFileId' : {
                    'validation': {
                        'required': false
                    }
                },
                'docxOrPdf' : {
                    'validation': {
                        'required': false
                    }
                },
                'enableMailAlertShowMore' : {
                    'validation': {
                        'required': false
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

}
